<template>
    <div style="overflow-x:scroll">
        <div class="w-full" >
            <div style="border:1px solid #E4E7EB; background-color:#FFF; display:inline-block; min-width:100%">
                <div class="dF">
                    <div class="p-3" style="border-right:1px solid #E4E7EB" @change="(e) => allCheck(e, unit)"><a-checkbox :indeterminate="indeterminate" :checked="checkAll" /></div>

                        <div class="dF" style="flex:1">
                            <div class="p-3" style="min-width:200px; width:25%; border-right:1px solid #E4E7EB; font-weight:600">Thumbnail</div>
                            <div class="p-3" style="min-width:120px; width:20%; border-right:1px solid #E4E7EB; font-weight:600">Model Name</div>
                            <div class="p-3" style="min-width:110px; width:20%; border-right:1px solid #E4E7EB; font-weight:600">Model Code</div>
                            <div class="p-3" style="min-width:110px; width:20%; border-right:1px solid #E4E7EB; font-weight:600">Model Type</div>
                            <div class="p-3" style="min-width:100px; width:15%; border-right:1px solid #E4E7EB; font-weight:600">Frontage</div>
                        </div>
                        <div class="dF">
                            <div class="p-3" style="width:150px; border-right:1px solid #E4E7EB; font-weight:600">Elevation</div>
                            <div class="p-3" style="width:200px; border-right:1px solid #E4E7EB; font-weight:600">Floor Plan Option</div>
                            <div class="p-3" style="width:120px; border-right:1px solid #E4E7EB; font-weight:600">Area Size</div>
                            <div class="p-3" style="width:200px; border-right:1px solid #E4E7EB; font-weight:600">Features</div>
                            <div class="p-3" style="width:120px; border-right:1px solid #E4E7EB; font-weight:600">Price</div>
                            <div class="p-3" style="width:100px; font-weight:600">Visibility</div>
                            <div class="p-3" style="width:50px"></div>
                        </div>

                </div>
            </div>

            <div v-for="(model, modelI) in models" :key="model.id + modelI" class="dF mb-3" :style="modelI == 0 ? 'border-top:none': '' " style="border:1px solid #E4E7EB; background-color:#FFF; display:inline-block; min-width:100%">
                <div :list-data-value="model.id" class="model-row dF">
                    <div class="p-3 dF aC" style="border-right:1px solid #E4E7EB"><a-checkbox :checked="selectedArray.includes(model.id)" @change="selectModel(model.id)" /></div>

                         <div class="dF" style="flex:1">
                            <div v-if="model.image" class="p-3 dF aC" style="min-width:200px; width:25%; border-right:1px solid #E4E7EB;">
                                <!-- <img style="width:100%;" :src="model.image" /> -->
                                <div class="w-full h-full" :style="{'background-image': `url(${model.image})`,'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'contain', 'min-height': '100px'}"></div>
                            </div>
                            <div v-else class="p-3 dF aC" style="min-width:200px; width:25%; border-right:1px solid #E4E7EB">
                                <img style="width:100%;" src="@/assets/no-image.png" />
                            </div>
                            <div class="p-3 dF aC" style="min-width:120px; width:20%; border-right:1px solid #E4E7EB; font-weight:600">{{model.name}}</div>
                            <div class="p-3 dF aC" style="min-width:110px; width:20%; border-right:1px solid #E4E7EB">{{model.code}}</div>
                            <div class="p-3 dF aC" style="min-width:110px; width:20%; border-right:1px solid #E4E7EB">{{model.type}}</div>
                            <div class="p-3 dF aC" style="min-width:100px; width:15%; border-right:1px solid #E4E7EB">{{model.size}}</div>
                        </div>
                        <div class="dF">
                            <div style="width:150px; border-right:1px solid #E4E7EB">
                                <div v-if="model.units.length > 1">
                                    <div class="p-3 dF aC" style="min-height:74.44px; height:100%;" :style="{'border-top':(unitI == 0 ? 'none':'1px solid #E4E7EB'), 'height':(unit.packages.length == 1 ? '':`${unit.packages.length * 74.44}px`)}" v-for="(unit, unitI) in model.units" :key="unitI + unit.id">{{unit.name}}</div>
                                </div>
                                <div v-else-if="model.units.length == 1" class="p-3 dF aC" style="height:100%">{{model.units[0].name}}</div>
                                <div class="p-3 dF aC" style="height:100%;" v-else>No elevation</div>
                            </div>
                            <div style="width:200px; border-right:1px solid #E4E7EB">
                                <div v-if="model.units.length > 1">
                                    <div v-for="(unit, unitI) in model.units" :key="unitI + unit.id">
                                        <div class="dF aC p-3" style="min-height:74.44px; height:100%; border-top:1px solid #E4E7EB" :style="packI == 0 && unitI == 0 ? 'border-top:none':''" v-for="(pack, packI) in unit.packages" :key="packI + pack.id">{{pack.name}}</div>
                                    </div>
                                </div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length == 1" class="p-3 dF aC" style="height:100%">{{model.units[0].packages[0].name}}</div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length > 1">
                                    <div v-for="(pack,packI) in model.units[0].packages" :key="pack.id + packI" :value="pack.id + packI" style="min-height:74.44px; border-top:1px solid #E4E7EB" :style="packI == 0 ? 'border-top:none':''" class="p-3 dF aC">{{pack.name}}</div>
                                </div>
                                <div class="p-3 dF aC" style="min-height:74.44px; height:100%" v-else>No elevation</div>
                            </div>
                            <div style="width:120px; border-right:1px solid #E4E7EB">
                                <div v-if="model.units.length > 1">
                                    <div v-for="(unit, unitI) in model.units" :key="unitI + unit.id">
                                        <div class="p-3 dF aC" style="min-height:74.44px; height:100%; border-top:1px solid #E4E7EB" :style="unitI == 0  && packI == 0 ? 'border-top:none':''" v-for="(pack, packI) in unit.packages" :key="pack.id + packI"><span v-if="pack.sqft">{{`${pack.sqft} Sq.Ft`}}</span></div>
                                    </div>
                                </div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length == 1" class="p-3 dF aC" style="height:100%">{{`${model.units[0].packages[0].sqft} Sq.Ft`}}</div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length > 1">
                                    <div v-for="(pack,packI) in model.units[0].packages" :key="pack.id + packI" :value="pack.id + packI" style="min-height:74.44px; border-top:1px solid #E4E7EB" :style="packI == 0 ? 'border-top:none':''" class="p-3 dF aC"><span v-if="pack.sqft">{{`${pack.sqft} Sq.Ft`}}</span></div>
                                </div>
                                <div class="p-3 dF aC" style="min-height:74.44px; height:100%;" v-else>No elevation</div>
                            </div>
                            <div style="width:200px; border-right:1px solid #E4E7EB; line-height: 1;">
                                <div v-if="model.units.length > 1">
                                    <div v-for="(unit, unitI) in model.units" :key="unitI + unit.id">
                                        <div v-for="(pack, packI) in unit.packages" :key="pack.id + packI" class="p-3" :style="unitI == 0 && packI == 0 ? 'border-top:none' : ''" style="height:74.44px; border-top:1px solid #E4E7EB" >{{`${pack.beds || 0} Beds &emsp; ${pack.dens || 0} Den/Library &emsp; ${pack.baths || 0} Baths &emsp; ${pack.powderRooms || 0} Pwd &emsp; ${pack.garages || 0} Car Garages &emsp; ${pack.stories || 0} Stories`}}</div>
                                    </div>
                                </div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length == 1" class="p-3 dF aC" style="height:100%">{{`${model.units[0].packages[0].beds || 0} Beds &emsp; ${model.units[0].packages[0].dens || 0} Den/Library &emsp; ${model.units[0].packages[0].baths || 0} Baths &emsp; ${model.units[0].packages[0].powderRooms || 0} Pwd &emsp; ${model.units[0].packages[0].garages || 0} Car Garages &emsp; ${model.units[0].packages[0].stories || 0} Stories`}}</div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length > 1">
                                    <div v-for="(pack,packI) in model.units[0].packages" :key="pack.id + packI" :value="pack.id + packI" style="min-height:74.44px; border-top:1px solid #E4E7EB" :style="packI == 0 ? 'border-top:none':''" class="p-3 dF aC">{{`${pack.beds || 0} Beds &emsp; ${pack.baths || 0} Baths &emsp; ${pack.garages || 0} Car Garages &emsp; ${pack.floors && pack.floors.length || 0} Stories`}}</div>
                                </div>
                                <div v-else class="p-3 dF aC" style="min-height:74.44px; height:100%;">No elevation</div>
                            </div>
                            <div style="width:120px; border-right:1px solid #E4E7EB">
                                <div v-if="model.units.length > 1">
                                    <div v-for="(unit, unitI) in model.units" :key="unitI + unit.id">
                                        <div v-for="(pack, packI) in unit.packages" :key="pack.id + packI" class="p-3 dF aC" style="min-height:74.44px; height:100%; border-top:1px solid #E4E7EB" :style="unitI == 0 && packI == 0 ? 'border-top:none':''">{{!pack.price ? 'TBD':`$ ${pack.price}`}}</div>
                                    </div>
                                </div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length == 1" class="p-3 dF aC" style="height:100%">{{!model.units[0].packages[0].price ? 'TBD' : `$ ${model.units[0].packages[0].price}`}}</div>
                                <div v-else-if="model.units.length == 1 && model.units[0].packages.length > 1">
                                    <div v-for="(pack,packI) in model.units[0].packages" :key="pack.id + packI" :value="pack.id + packI" style="min-height:74.44px; border-top:1px solid #E4E7EB" :style="packI == 0 ? 'border-top:none':''" class="p-3 dF aC">{{!pack.price ? 'TBD' : `$ ${pack.price}`}}</div>
                                </div>
                                <div v-else class="p-3 dF aC" style="min-height:74.44px; height:100%;">No elevation</div>
                            </div>
                            <div class="p-3 dF aC" style="width:100px; font-weight: bold;">{{model.status.charAt(0).toUpperCase() + model.status.slice(1)}}</div>
                            <!-- <a-popover>
                                <template slot="content">
                                    <div>adf</div>
                                    <div>adf</div>
                                    <div>adf</div>
                                </template> -->
                            <div class="p-3 dF aC" style="width:50px">
                                <a-popover overlayClassName="no-padding">
                                <div slot="content">
                                    <div style="cursor:pointer" @click="editModel(model.id)" class="option dF aC p-2">
                                        <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
                                        <div>Edit Model</div>
                                    </div>
                                    <div style="cursor:pointer" @click="duplicateModel(model.id)" class="option dF aC p-2">
                                        <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                        <div>Duplicate Model</div>
                                    </div>
                                    <div style="cursor:pointer" @click="moveModel(model.id)" class="option dF aC p-2">
                                        <div class="mr-3"><a-icon type="export" /></div>
                                        <div>Move Model</div>
                                    </div>
                                    <div @click="previewModel(model.id)"  style="cursor:pointer" class="option dF aC p-2">
                                        <div class="mr-3"><i class="fe fe-eye" /></div>
                                        <div>Preview Model</div>
                                    </div>

                                    <div style="cursor:pointer" @click="deleteModel(model.id)" class="option dF aC p-2">
                                        <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                        <div>Delete Model</div>
                                    </div>
                                </div>
                                <div class="relative more-option dF aC" >
                                    <div class="absolute circle" style="background-color:#FAF9F9; width:50px; height:50px; border-radius:50%; left:-18px;"></div>
                                    <a-icon class="more-icon" style="z-index:10; cursor:pointer" type="more" />
                                </div>
                                </a-popover>
                            </div>
                            <!-- </a-popover> -->
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        models:{
            type:Array,
            default:() => []
        },
        unit:{
            type:Object,
            default:() => {}
        }
    },
    watch:{
        unitGroups: function (val) {
            console.log('newwwwwww',val)
        },
        selectedArray:{
            handler(val){
                let id = this.unit.children.map(x => x.id)
                let array = []
                val.forEach(x => {
                    if (id.includes(x)) array.push(x)
                })
                if (array.length > 0 && array.length < id.length) {
                    this.indeterminate = true
                } else if (array.length > 0 && array.length == id.length) {
                    this.checkAll = true
                    this.indeterminate = false
                } else if (array.length == 0) {
                    this.indeterminate = false
                    this.checkAll = false
                }
            }
        }
    },
    computed:{
        selectedArray() {
            return this.$store.state.models.selectedArray
        },
        unitGroups(){
            let groups = this.$store.state.models.unitGroups
            let time = this.time
            return groups
        },
        instance() {
            return this.$store.state.instance
        }
    },
    data(){
        return{
            // selectedArray:[],
            indeterminate:false,
            checkAll:false,
            time:0,
            deleteId:0,
        }
    },
    methods:{
        moveModel(id) {
            this.$emit('moveModelList', id)
        },
        allCheck(e, unit){
            if (e.target.checked) {
                let array = JSON.parse(JSON.stringify(this.selectedArray))
                unit.children.forEach(model => {
                    if (!array.includes(model.id)) {
                        this.$emit('bulkActionModels', model.id)
                    }
                })
            } else {
                let array = JSON.parse(JSON.stringify(this.selectedArray))
                unit.children.forEach(x => {
                    if (array.includes(x.id)) this.$emit('bulkActionModels', x.id)
                })
            }
        },
        selectModel(id) {
            this.$emit('bulkActionModels', id)
        },
        previewModel(id) {
            this.$emit('previewModelList',id)
        },
        editModel(id){
            console.log('idddd',id)
            let crumbs = this.$store.state.models.crumbs
            let model = this.unitGroups[id]
            crumbs.push(model.name)
            this.$store.commit('SET_CRUMBS', crumbs)
            this.$router.push(`/modeldetails/${id}`)
        },
        deleteModel(id){
            console.log('idd', id)
            this.deleteId = id
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to unpublish this Home Model. Unpublishing will permanently unpublish the all the Models in this Home Model Type</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.delete()
                },
                onCancel() {
                    console.log('Cancel')
                }
            })

        },
        duplicateModel(id) {
            console.log('id', id)

            if (!this.unitGroups[id]) return console.error('doesnt exist')

            let duplicate = JSON.parse(JSON.stringify(this.unitGroups[id]))
            duplicate.status = 'draft'
            duplicate.name += ` (copy)`
            delete duplicate.id
            delete duplicate._id
            this.$api.put(`/unit-groups/${this.instance.id}/${id}/clone`).then(({data}) => {
                this.time = Date.now()
                this.$emit('force_update', this.time)
                this.$store.commit('DUPLICATE_MODEL', data)
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            console.log('updated', this.unitGroups)
        },
        delete() {
            this.$api.delete(`/units/${this.instance.id}/unitgroups/${this.deleteId}`).then( ({data}) => {

                this.$store.commit('SET_PROP', {where:['unitGroups',this.deleteId], del:true})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})

        },
    },
    created () {
        let id = this.unit.children.map(x => x.id)
        let array = []
        this.selectedArray.forEach(x => {
            if (id.includes(x)) array.push(x)
        })
        if (array.length > 0 && array.length < id.length) {
            this.indeterminate = true
        } else if (array.length > 0 && array.length == id.length) {
            this.checkAll = true
            this.indeterminate = false
        } else if (array.length == 0) {
            this.indeterminate = false
            this.checkAll = false
        }
    }
}
</script>

<style scoped>
.option:hover {
    background-color:#FAF9F9;
}
.table-column {
    flex-grow:1;
    background-color:#FFF;
    /* padding:16px;  */
}
.table-cell {
    padding:16px;
}
.more-option:hover .circle {
    opacity: 1;
}
.more-option:hover .more-icon {
    color:var(--orange)
}
.more-option:not(:hover) .circle {
    opacity: 0;
}
.model-row:hover{
    background-color: var(--off-white-dark);
}
</style>
<style>
.no-padding .ant-popover-inner-content{
    padding:0 !important;
    min-width:150px;
}
</style>
