<template>
    <a-card class="model-card-display" :card-data-value="model.id" @mouseover="showOptions = true" @mouseleave="showOptions = false">
        <div :data-value="model.id">
            <div v-if="imgSrc" class="relative">
				<div v-if="model.isStandingInventory" class="absolute" style="top: 10px; left: 10px;">
					<a-tag color="#3395c6">Standing Inventory</a-tag>
				</div>
                <div v-show="showOptions==true" class="option-select">
					<a-button-group>
						<a-tooltip placement="top" overlayClassName="change-tooltip-color">
							<template slot="title">
							<span>Edit Model</span>
							</template>
							<a-button @click="$emit('editModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></a-button>
						</a-tooltip>

						<a-tooltip placement="top" overlayClassName="change-tooltip-color">
							<template slot="title">
							<span>Duplicate Model</span>
							</template>
							<a-button @click="$emit('duplicateModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></a-button>
						</a-tooltip>

						<a-tooltip placement="top" overlayClassName="change-tooltip-color">
							<template slot="title">
							<span>Move Model</span>
							</template>
							<a-button @click="$emit('moveModel')"><a-icon type="export" /></a-button>
						</a-tooltip>

						<a-tooltip placement="top" overlayClassName="change-tooltip-color">
							<template slot="title">
							<span>Preview Model</span>
							</template>
							<a-button @click="$emit('previewModel', model.id)"><i class="fe fe-eye" /></a-button>
						</a-tooltip>

						<a-tooltip placement="top" overlayClassName="change-tooltip-color">
							<template slot="title">
							<span>Delete Model</span>
							</template>
							<a-button style="color:#FD647C" @click="$emit('deleteModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a-button>
						</a-tooltip>

					</a-button-group>
				</div>
                <img style="object-fit: cover; width:100%; height:220px; " :src="imgSrc">
					<a-checkbox :checked="test.includes(model.id)" @change="selectThis" style="position:absolute; right: 10px; top:10px;" />
                </img>

                <!-- <div v-if="showOptions==true">
                    <a-button-group>
                        <a-button><a-icon type='edit' /></a-button>
                        <a-button><a-icon type='delete' /></a-button>
                    </a-button-group>
                </div> -->
            </div>
            <div v-else style="text-align:center;background-color:var(--off-white-dark);">
                <div class="relative">
					<div v-if="model.isStandingInventory" class="absolute" style="top: 10px; left: 10px;">
						<a-tag color="#3395c6">Standing Inventory</a-tag>
					</div>
                    <!-- <h2 style="padding-top:50px">No Image</h2> -->
                    <a-checkbox :checked="test.includes(model.id)" @change="selectThis" style="position:absolute; right: 10px; top: 10px;" />
                    <div v-show="showOptions==true" class="option-select">
                        <a-button-group>
                            <a-tooltip placement="top" overlayClassName="change-tooltip-color">
                                <template slot="title">
                                <span>Edit Model</span>
                                </template>
                                <a-button @click="$emit('editModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></a-button>
                            </a-tooltip>

                            <a-tooltip placement="top" overlayClassName="change-tooltip-color">
                                <template slot="title">
                                <span>Duplicate Model</span>
                                </template>
                                <a-button @click="$emit('duplicateModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></a-button>
                            </a-tooltip>

                            <a-tooltip placement="top" overlayClassName="change-tooltip-color">
                                <template slot="title">
                                <span>Move Model</span>
                                </template>
                                <a-button @click="$emit('moveModel')"><a-icon type="export" /></a-button>
                            </a-tooltip>

                            <a-tooltip placement="top" overlayClassName="change-tooltip-color">
                                <template slot="title">
                                <span>Preview Model</span>
                                </template>
                                <a-button @click="$emit('previewModel', model.id)"><i class="fe fe-eye" /></a-button>
                            </a-tooltip>

                            <a-tooltip placement="top" overlayClassName="change-tooltip-color">
                                <template slot="title">
                                <span>Delete Model</span>
                                </template>
                                <a-button style="color:#FD647C" @click="$emit('deleteModel')"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a-button>
                            </a-tooltip>

                        </a-button-group>
                    </div>
                    <img src="@/assets/no-image.png" style="height:220px; width:100%"/>
                </div>
            </div>
            <div class="mt-3 dF aC" style="justify-content:space-between">
                <h5 style="font-size:20px; margin-bottom:0">{{ name }}<span v-if="model.status == 'draft'"> (Draft)</span></h5>
                <div style="color:#A3A5AD" v-if="units.length">{{getPrice(model.units)}}</div>
            </div>
            <hr>
            <div style="width:100%;" v-if="units.length">
				<div class="dF jSB">
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minBed">
							{{model.minBed == model.maxBed ? model.minBed : `${model.minBed}-${model.maxBed}` }} Beds &nbsp;
						</span>
					</div>
					<div v-if="model.minBed">|</div>
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minDen">
							{{ model.minDen == model.maxDen ? model.minDen : model.minDen >= 0 ? `${model.minDen}-${model.maxDen}` : 0 }} Den/Library &nbsp;
						</span>
					</div>
					<div v-if="model.minDen">|</div>
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minBath">
							{{model.minBath == model.maxBath ? model.minBath : `${model.minBath}-${model.maxBath}` }} Baths &nbsp;
						</span>
					</div>
					<div v-if="model.minBath">|</div>
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minPowderRoom">
							{{ model.minPowderRoom == model.minPowderRoom ? model.minPowderRoom : model.minPowderRoom >= 0 ? `${model.minPowderRoom}-${model.minPowderRoom}` : 0 }} Pwd &nbsp;
						</span>
					</div>
					<div v-if="model.minPowderRoom">|</div>
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minGarage">
							{{model.minGarage == model.maxGarage ? model.minGarage : `${model.minGarage}-${model.maxGarage}` }} Garages &nbsp;
						</span>
					</div>
				</div>
				<div class="dF">
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minStories">
							{{model.minStories == model.maxStories ? model.minStories : `${model.minStories}-${model.maxStories}` }} Stories &nbsp;
						</span>
					</div>
					<div v-if="model.minStories" class="mx-2">|</div>
					<div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
						<span v-if="model.minSqft">
							{{model.minSqft == model.minSqft ? model.minSqft : `${model.minSqft}-${model.maxSqft}` }} Sq.ft
						</span>
					</div>
				</div>
            </div>
            <div style="width:100%" v-else>
               <div>No Elevations</div>
            </div>

        </div>

    </a-card>

</template>

<script>
export default {
    props:{
        imgSrc:{
            default: '',
            type: String
        },
        units:{
            type:Array,
            default:[]
        },
        name:{
            default: '',
            type: String
        },
        test:{
            default:[],
            type: Array
        },
        model:{
            default:{},
            type:Object,
        }
        // beds: {
        //     default:'',
        //     type: Number
        // },
        // baths: {
        //     default:'',
        //     type: Number
        // },
        // garages: {
        //     default: '',
        //     type: Number
        // },
        // sqft: {
        //     defaul: '',
        //     type: Number
        // },
        // stories: {
        //     default: '',
        //     type: Number
        // },

    },
    computed: {

    },
    watch: {

    },
    data() {
        return {
            showOptions:false,

        }
    },
    methods:{
        selectThis(){
            this.$emit('toggleSelect')
        },
        getPrice(obj){
            let prices = []
            obj.forEach(y => {
                if (y.packages.length){
                    y.packages.forEach(x => {
                        if (!prices.includes(x.price)){
                            prices.push(x.price);
                        }
                    })
                }
            })

            prices = prices.filter(x => x != null && x != undefined).sort((a,b) => a-b)
			if (prices.length == 1 && prices[0]){
				return '$' + prices[0].toLocaleString()
			} else if (prices && prices.length > 1 && prices[0] && prices[prices.length-1]){
				return '$' + prices[0].toLocaleString() + ' - ' + '$' + prices[prices.length-1].toLocaleString()
			} else {
				return '$0'
			}
        },
    }
}
</script>

<style>
.option-select{
    position: absolute;
	z-index: 1000;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    /* bottom: 200px;
    left: 50%;
    margin-left: -90px; */
	/* margin-top: 75px; */
}
.test .ant-popover-inner-content{
    padding:0 !important;
}
</style>

<style lang="scss">
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background:#3F3356;
    }
</style>
<style lang="scss" scoped>
.model-card-display:hover{
    background-color:var(--off-white-dark);
}
</style>
